export default function() {

    var panels = document.querySelectorAll(".panel");

    panels.forEach((panel, index) => {

        let toggler = panel.querySelectorAll('.panel-toggler')[0];

        panel.id = 'accordion' + index;

        toggler.addEventListener('click', () => {
            panels.forEach(tempPanel => {
                if (tempPanel !== panel) {
                    tempPanel.getElementsByClassName('panel-toggler')[0].classList.remove('rotate-arrow');
                    tempPanel.getElementsByClassName('panel-left')[0].classList.remove('panel-expanded');
                    tempPanel.getElementsByClassName('panel-right')[0].classList.remove('panel-collapsed');
                    tempPanel.getElementsByClassName('panel-hidden-content')[0].classList.add('hidden');
                }
            });

            toggler.classList.toggle('rotate-arrow');
            panel.getElementsByClassName('panel-left')[0].classList.toggle('panel-expanded');
            panel.getElementsByClassName('panel-right')[0].classList.toggle('panel-collapsed');
            panel.getElementsByClassName('panel-hidden-content')[0].classList.toggle('hidden');

            goPanelTop(panel.id);
        })

        function goPanelTop(panelAnchor){
            var top = document.getElementById(panelAnchor).offsetTop - 20;
            window.scrollTo(0, top);
        }
    })
}