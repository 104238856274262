import Glide from '@glidejs/glide';

export default function () {
    var glideComponents = document.getElementsByClassName("glide");

    if (glideComponents.length > 0) {
        for (var i=0; i < glideComponents.length; i++) {
            var glide = new Glide(glideComponents[i], {
                type: 'carousel',
                perView: 1,
                focusAt: 'center'
            });

            glide.mount({});
        }
    }
};