export default function() {

    var tabs = document.querySelectorAll(".tab-content-visible");

    tabs.forEach((tab, index) => {
        let hiddenElements = tab.nextElementSibling.querySelectorAll('[data-toggle]');
        tab.id = 'tab' + index;

        const allElements = [tab, ...hiddenElements];

        allElements.forEach(element => {
            element.addEventListener('click', () => {
                tabs.forEach(tempTab => {
                    if (tempTab !== tab) {
                        tempTab.classList.remove('active');
                    }
                });
                tab.classList.toggle('active');
                goTabTop(tab.id);
            })
        })
        function goTabTop(topAnchor){
            var top = document.getElementById(topAnchor).offsetTop - 20;
            window.scrollTo(0, top);
        }
    })
}