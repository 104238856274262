export default function () {
    const scrollToTop = document.getElementsByClassName('back-to-top')[0];

    scrollToTop.addEventListener('click', () => {
        const htmlScroll = window
            .getComputedStyle(document.querySelectorAll('html')[0], null)
            .getPropertyValue('scroll-behavior');

        if (htmlScroll === 'smooth') {
            window.scrollTo(0, 0);
        } else {
            const duration = 1000;
            const difference = document.documentElement.scrollTop || document.body.scrollTop;
            const startTime = performance.now();

            function step(timeStamp) {
                let normalizedTime = (timeStamp - startTime) / duration;
                if (normalizedTime > 1) normalizedTime = 1;

                window.scrollTo(0, difference * Math.cos(normalizedTime * Math.PI));
                if (normalizedTime < 1) window.requestAnimationFrame(step);
            }
            window.requestAnimationFrame(step);
        }
    })
}