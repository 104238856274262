import Overlay from 'ol/Overlay';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import {defaults as defaultInteractions, MouseWheelZoom} from 'ol/interaction';
import {focus} from 'ol/events/condition';
import {fromLonLat} from 'ol/proj';
import {defaults as defaultControls, ZoomToExtent} from 'ol/control';

export default function() {

    var officeLocation = fromLonLat([9.95033, 53.54497]);

    var map = new Map({
        target: 'map',
        layers: [
            new TileLayer({
                source: new OSM(),
                maxResolution: 1
            }),
            new TileLayer({
                source: new XYZ({
                    url: 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg',
                }),
                minResolution: 1,
            })
        ],
        controls: defaultControls({
            attributionOptions: {
                collapsible: false
            }
        }).extend([
            new ZoomToExtent({
                extent: [
                    1103194.102670351, 7082209.97555752, 1112137.2349797166, 7086681.541712203
                ]
            })
        ]),
        view: new View({
            center: officeLocation,
            zoom: 13
        }),
        interactions: defaultInteractions({mouseWheelZoom: false}).extend([
            new MouseWheelZoom({
                constrainResolution: true, // force zooming to a integer zoom
                condition: focus // only wheel/trackpad zoom when the map has the focus
            })
        ]),
    });
    //calculate map Extent
    // console.log(map.getView().calculateExtent());

    //change extent button html
    var mapExtent = document.querySelectorAll('.ol-zoom-extent')[0];
    if (mapExtent !== undefined) {
        mapExtent.childNodes[0].innerHTML = '&#10687';
    }

    //add marker
    var marker = new Overlay({
        position: officeLocation,
        positioning: 'center-center',
        element: document.getElementById('marker'),
        stopEvent: false
    });
    map.addOverlay(marker);

    var officeInfo = new Overlay({
        position: officeLocation,
        element: document.getElementById('officeInfo')
    });
    map.addOverlay(officeInfo);

    // add additional office info
    var closeInfo = document.getElementsByClassName('close-info')[0];

    const allElements = [closeInfo, document.getElementById('marker')];

    allElements.forEach(element => {
        if(element) {
            element.addEventListener('click', () => {
                document.getElementById('officeInfo').classList.toggle('hidden');
            })
        }
    })

}