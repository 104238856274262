// import 'core-js/es6/array';
import 'nodelist-foreach-polyfill';
import svg4everybody from 'svg4everybody';

import Slider from './components/slider';
import Accordion from './components/accordion';
import TabView from './components/tab-view';
import websiteMap from './components/map';
import scrollToTop from './components/scroll-to-top';

import TeamSpirit from './easter-eggs/team-spirit';

// Initialize slider
Slider();

// Initialize accordion
Accordion();

// Initialize tab-view
TabView();

// Initialize map
websiteMap();

// Initialize scrollToTop button
scrollToTop();

// render svg in IE
svg4everybody({
    polyfill: true
});

//burger menu

var burgerMenu = document.getElementById("burgerMenu");
var burgerMenuContent = document.getElementById("burgerMenuContent");

burgerMenu.onclick = function() {
    burgerMenu.classList.toggle("change");
    burgerMenuContent.classList.toggle("open");
};
window.addEventListener('resize', function () {
    if (window.innerWidth > 767) {
        burgerMenu.classList.remove("change");
        burgerMenuContent.classList.remove('open');
    }
});

// Initialize Team Spirit
TeamSpirit();