export default function () {

    // To get dev5310 team spirit move mouse at a Desktop view over persons in a following order: 1, 2, 3, 4, 8, 7, 6, 5 .
    // A movement should be done within 3 seconds.

    var hiddenBox = document.querySelector('.team-gif');
    var close = document.querySelector('.close-gif');
    var boxes = document.querySelectorAll('.person-container');

    var featurePattern = "0,1,2,3,7,6,5,4";
    var boxPattern = [];
    boxes.forEach((box, index) => {

        if (boxes.length < 9) {
            hiddenBox.classList.add('small');
        }

        box.addEventListener('mouseenter', () => {
            boxPattern.push(index);
            var matchPattern = boxPattern.toString().includes(featurePattern);

            if (matchPattern === true) {
                hiddenBox.style.display = "block";
            }
            var count = 0;
            var interval = setInterval(countUp, 1000);

            function countUp() {
                count++;

                if (count === 3) {
                    count = 0;
                    clearInterval(interval);
                    boxPattern = [];
                    countUp();
                }
            }

            close.addEventListener('click', function() {
                hiddenBox.style.display = "none";
                boxPattern = [];
            })
        });
    })
};